<!-- System: STA
    Purpose: In create projects modal user can create new
          projects.
-->
<template>
  <!-- Create Project Modal -->
  <v-dialog
    max-width="1000"
    class="pa-0 white-bg"
    v-model="create_modal"
    @click:outside="onHidden()"
  >
    <!-- Create Project button -->
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="selectedCompanyUserStatus !== 'employee' && showPermission"
        primary
        elevation="10"
        class="active-custom-btn mt-md-3 px-16 px-sm-5"
        @click="create_modal = true"
        v-on="on"
      >
        <span class="mdi mdi-plus">Create Project </span>
      </v-btn>
    </template>
    <!-- Create Project Card -->
    <v-card class="white-bg">
      <v-card-title>
        <span class="headline">Create New Project</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-container>
        <!-- Create Project Form -->
        <v-form
          @submit.prevent="onSubmit"
          v-model="valid"
          autocomplete="off"
          ref="createProjecrForm"
          id="create-project-form"
        >
          <!-- Project Image -->
          <v-row>
            <v-col cols="12" md="4">
              <div class="file-upload ml-3 mt-12">
                <div class="text-center cursor-poniter" v-if="!image">
                  <label class="fileContainer">
                    <div>
                      <img
                        src="../../assets/images/dashboard_icons/cloud_icon.svg"
                        height="100"
                        width="100"
                      />
                    </div>
                    <div>
                      Upload image of <b>Project logo</b> <br /><small
                        >max size 1 MB of dimensions 174 x 75</small
                      >
                    </div>
                    <input
                      @change="onFileChange"
                      accept="image/png, image/jpg, image/jpeg"
                      name="file_name"
                      type="file"
                    />
                  </label>
                </div>
                <div class="text-center" v-else>
                  <imageCroper
                    :cropHeight="74"
                    :cropWidth="174"
                    :imgSrc="image"
                    :imgStyle="{ width: 'auto', height: 'auto' }"
                    @croped_file="attach_crop_file($event)"
                    alt="Project Image"
                    v-if="!cropped_file_url"
                  />
                  <img
                    :src="cropped_file_url"
                    class="img-thumbnail text-center"
                    v-if="cropped_file_url"
                    height="150"
                    width="170"
                  />
                  <br />
                  <v-btn
                    @click="removeImage"
                    small
                    color="error"
                    type="button"
                    class="mb-3 mt-2"
                    v-if="cropped_file_url || !cropped_file_url"
                  >
                    Remove image
                  </v-btn>
                </div>
              </div>
              <!-- Project Name -->
              <div class="my-create-project mt-12 ml-3">
                <v-text-field
                  class="mt-n2"
                  id="exampleInput1"
                  name="project_name"
                  label="Project name"
                  outlined
                  :rules="projectNameRules"
                  clearable
                  type="text"
                  v-model.trim="form.project_name"
                >
                </v-text-field>

                <!-- Project Start Date -->
                <div class="mt-6 ml-0">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.start_date"
                        label="Start Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        onKeyDown="return false"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.start_date"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </div>

                <!-- Project Invite Member By Email Component -->
                <div class="mt-12 mr-0">
                  <ProjectInviteMember />
                </div>

                <!-- Create Project Button -->
                <v-btn
                  :disabled="!valid || createLoader"
                  class="create-project-button mt-10 mb-3"
                  @click="onSubmit"
                  ref="submit"
                  type="submit"
                  color="primary"
                  primary
                >
                  <v-progress-circular
                    indeterminate
                    v-if="createLoader"
                    size="20"
                  >
                  </v-progress-circular>
                  Create Project
                </v-btn>
              </div>
            </v-col>
            <!-- Add Existing Members Area -->
            <v-col cols="12" md="8" style="max-height: 500px; overflow: scroll">
              <div v-if="search_members.length > 0">
                <v-row class="heading search-member-pro">
                  <v-col cols="12" lg="6">
                    <div class="title pt-3 ml-n16">Add Existing Members</div>
                  </v-col>
                  <v-spacer> </v-spacer>
                  <v-col cols="12" lg="6">
                    <!-- Search Bar -->
                    <v-text-field
                      outlined
                      dense
                      autocomplete="off"
                      class="mt-2"
                      v-model="member_search"
                      counter="25"
                      label="Search Member"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    employer_employees.length > 0 && search_members.length > 0
                  "
                  class="col-12 mt-n5 mx-auto"
                >
                  <!-- Select All Member Checkbox  -->
                  <input
                    @change="inviteAllMembers"
                    class="d-inline mt-1 mr-2"
                    id="inviteAllMember"
                    type="checkbox"
                    v-model="invite_all_members"
                    value="1"
                  />
                  <label class="mb-0 d-inline" for="inviteAllMember"
                    >Select All Members</label
                  >
                </v-row>

                <!-- Email Chip For Members Invited By Email -->
                <div
                  class="m-0 row selected-members mb-3"
                  v-if="inviteMembersFormData.emails"
                >
                  <v-chip
                    class="ma-2"
                    close
                    color="green"
                    outlined
                    @click:close="removeSelected(key)"
                    :key="selected.id"
                    @click="removeSelected(key)"
                    v-for="(selected, key) in inviteMembersFormData.emails"
                  >
                    {{ selected }}
                  </v-chip>
                </div>

                <!-- Existing Member Cards -->
                <v-row class="m-0 ml-4 pro-members-wrapper">
                  <div
                    :key="index"
                    @click="moveToSelected(member)"
                    class="m-0 mb-3 pl-0 pr-2 box-wi"
                    v-for="(member, index) in search_members"
                  >
                    <SingleMember
                      :member="member"
                      :selected_members="form.member_ids"
                    />
                  </div>
                </v-row>
              </div>

              <!-- Else Part -->

              <!-- Add Existing Members Area -->
              <div class="col-xl-8 flex-center" v-else>
                <v-row class="heading">
                  <v-col cols="12" lg="6">
                    <h6 class="title pt-3 pl-2">Add Existing Members</h6>
                  </v-col>
                  <v-spacer> </v-spacer>
                  <!-- Search Bar -->
                  <v-col cols="12" lg="6">
                    <v-text-field
                      v-model="member_search"
                      counter="25"
                      label="Search Member"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    employer_employees.length > 0 && search_members.length > 0
                  "
                  class="col-12 mt-3 p-0 checkbox-div mb-2 sel-check checkbox-pro"
                >
                  <!-- Select All Member Checkbox  -->

                  <input
                    @change="inviteAllMembers"
                    class="d-inline mt-1 mr-2"
                    id="inviteAllMember"
                    type="checkbox"
                    v-model="invite_all_members"
                    value="1"
                  />
                  <label class="mb-0 d-inline" for="inviteAllMember"
                    >Select All Members</label
                  >
                </v-row>
                <!-- No Member Found Component -->
                <div class="mb-5" v-else>
                  <LoadingMember v-if="fetchLoader" />
                  <NoMemberFound v-else />
                </div>
                <!-- Email Chip For Members Invited By Email -->
                <div
                  class="m-0 row selected-members mb-3"
                  v-if="inviteMembersFormData.emails"
                >
                  <v-chip
                    class="ma-2"
                    close
                    color="green"
                    outlined
                    @click:close="removeSelected(key)"
                    :key="selected.id"
                    @click="removeSelected(key)"
                    v-for="(selected, key) in inviteMembersFormData.emails"
                  >
                    {{ selected }}
                  </v-chip>
                </div>

                <!-- Existing Member Card -->
                <v-row class="m-0 ml-4 pro-members-wrapper">
                  <div
                    :key="index"
                    @click="moveToSelected(member)"
                    class="m-0 mb-3 pl-0 pr-2 box-wi"
                    v-for="(member, index) in search_members"
                  >
                    <SingleMember
                      :member="member"
                      :selected_members="form.member_ids"
                    />
                  </div>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { ProjectNameRules, required } from "@/Constants/Const";
import { mapState } from "vuex";
import { baseURL } from "@/assets/js/urls";
export default {
  name: "ProjectCreateBody",
  components: {
    NoMemberFound: () => import("@/components/includes/errors/NoMemberFound"),
    LoadingMember: () => import("@/components/includes/errors/LoadingMember"),
    imageCroper: () => import("@/components/includes/helper/ImageCropper"),
    SingleMember: () => import("@/components/projects/SingleMember"),
    ProjectInviteMember: () =>
      import("@/components/projects/ProjectInviteMember"),
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      projectNameRules: ProjectNameRules,
      createLoader: false,
      fetchLoader: false,
      valid: true,
      menu1: false,
      create_modal: false,
      add_exist_mem: false,
      teamMembers: [],
      form: {
        project_name: "",
        logo: {
          name: null,
        },
        start_date: null,
        member_ids: [],
      },
      invite_all_members: "",
      picturePath: "",
      name: "",
      image: "",
      member_search: "",
      is_cropped: false,
      cropped_file_url: "",
    };
  },
  computed: {
    ...mapState("custom", [
      "employer_employees",
      "selected_company",
      "inviteMembersFormData",
      "companies_list",
    ]),
    /**
     * Return searched members list
     */
    search_members() {
      let members = [];
      this.member_search = this.member_search.trimStart();
      this.employer_employees.forEach((member) => {
        if (
          member.name &&
          member.name.toLowerCase().includes(this.member_search.toLowerCase())
        ) {
          members.push(member);
        }
      });
      if (members.length > 0) {
        this.$root.$emit("size");
      }
      return members;
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.project) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
  },
  watch: {
    create_modal() {
      if (this.create_modal == false) {
        this.form.start_date = null;
        this.member_search = "";
        setTimeout(() => {
          this.form.member_ids = [];
        }, 500);
      } else if (this.$store.state.custom.employer_employees.length == 0) {
        this.getEmployeeEmployers();
      }
    },

    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  mounted() {
    if (this.$store.state.custom.employer_employees.length == 0) {
      this.getEmployeeEmployers();
    }
  },
  methods: {
    /**
     * This function is resposible for clearing
     * form data when modal is close
     */
    onHidden() {
      this.$store.commit("custom/inviteMembersFormData", {});
      this.add_exist_mem = false;
      this.form = {
        project_name: "",
        logo: {
          name: null,
        },
        start_date: null,
        member_ids: [],
      };
      this.invite_all_members = "";
      this.image = "";
      this.removeImage();
    },
    /**
     * This function is resposible for Formating
     * date pattern
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    /**
     * This function is resposible for Creating
     * a new project by submiiting the form data
     */
    onSubmit(e) {
      e.preventDefault();
      let formData = new FormData(
        document.getElementById("create-project-form")
      );
      if (!this.valid) {
        return;
      }
      if (this.$store.state.custom.projects_list) {
        let index = this.$store.state.custom.projects_list.active.findIndex(
          (option) => option.project_name === this.form.project_name
        );
        if (index > -1) {
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Please enter unique project name its already exists",
            snackbarColor: "red",
          });
          return false;
        }
        let archived_index = this.$store.state.custom.projects_list.archived.findIndex(
          (option) => option.project_name === this.form.project_name
        );
        if (archived_index > -1) {
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Please enter unique project name its already exists",
            snackbarColor: "red",
          });
          return false;
        }
      }
      let members = this.form.member_ids.map((value) => value.id);
      formData.append("company_id", this.$route.params.id);
      if (this.inviteMembersFormData.emails) {
        let inviteMemDesignations = this.cleanArray(
          this.inviteMembersFormData.designations
        );
        this.cleanArray(this.inviteMembersFormData.emails).forEach(
          (member, index) => {
            formData.append("designations[]", inviteMemDesignations[index]);
            formData.append("emails[]", member);
          }
        );
      }
      if (members) {
        members.forEach((member_id) => {
          formData.append("member_ids[]", member_id);
        });
      }
      formData.append("logo", this.picturePath);
      this.createLoader = true;
      this.$store
        .dispatch("custom/createProject", formData)
        .then((response) => {
          this.createLoader = false;
          if (response.data.error === false) {
            this.$store.dispatch("custom/getEmployeeEmployers", {
              company_id: this.$route.params.id,
            });
            this.$store.commit("custom/toggle_loader", false);
            this.$store.commit("custom/inviteMembersFormData", {});
            this.$store.dispatch("custom/getProjects", {
              company_id: this.$route.params.id,
            });
            this.create_modal = false;
            this.$root.$emit("snack_bar", {
              show: true,
              message: "High Five!, The project has been created.,",
              snackbarColor: "green",
            });
          }
          this.form = {
            project_name: "",
            logo: {
              name: null,
            },
            start_date: null,
            member_ids: [],
          };
          this.invite_all_members = "";
          this.image = "";
        })
        .catch((error) => {
          this.createLoader = false;
          if (error.response.data.errors) {
            this.$store.commit("custom/toggle_loader", false);
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.errors.project_name,
              snackbarColor: "red",
            });
          } else if (error.response.data.error === true) {
            this.$store.commit("custom/toggle_loader", false);
            setTimeout(() => {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.error_msg,
                snackbarColor: "red",
              });
            }, 500);
          }
        });
    },
    getEmployeeEmployers() {
      let company_id = this.$route.params.id;
      this.fetchLoader = true;
      this.$store
        .dispatch("custom/getEmployeeEmployers", { company_id })
        .then(() => {
          this.fetchLoader = false;
        })
        .catch(() => {
          this.fetchLoader = false;
        });
    },
    /**
     * This function is resposible for moving the
     * user card into selected member list
     */
    moveToSelected(option) {
      if (this.form.member_ids.find((member) => member.id === option.id)) {
        this.removeFromSelected(option);
        return;
      }
      this.member_search = "";
      this.form.member_ids.push(option);
    },
    /**
     * This function is resposible for re-moving the
     * user card from member list
     */
    removeFromSelected(option) {
      this.form.member_ids.forEach((value, index) => {
        if (value.id === option.id) this.form.member_ids.splice(index, 1);
      });
    },
    /**
     * This function is resposible for removing the
     * member chip from invite new member list
     */
    removeSelected(option) {
      this.$store.commit("custom/splice_inviteMembersFormData", option);
    },
    /**
     * This function is resposible for selecting
     * all member move into the array
     */
    inviteAllMembers() {
      this.member_search = "";
      this.form.member_ids = [];
      if (!this.invite_all_members) {
        this.form.member_ids = [];
      } else {
        this.employer_employees.find((member) => {
          this.form.member_ids.push(member);
        });
      }
    },
    /**
     * Below These function is resposible for Image
     * uploading , croping and removing
     */
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let _size = file.size;
      if (_size > 1000000) {
        return;
      }
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      this.picturePath = file;
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.image = "";
      this.picturePath = "";
      this.cropped_file_url = "";
      this.is_cropped = false;
    },
    // Will remove all falsy values: undefined, null, 0, false, NaN and "" (empty string)
    cleanArray(actual) {
      let newArray = [];
      for (let i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i]);
        }
      }
      return newArray;
    },
    attach_crop_file(file_url) {
      let file_path = this.dataURLtoFile(file_url, "project_logo");
      this.cropped_file_url = file_url;
      this.picturePath = file_path;
      this.is_cropped = true;
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    url() {
      return baseURL.API_URL;
    },
  },
};
</script>

<style scoped>
.error-msg {
  color: tomato;
  font-size: 12px;
}
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}

.file-upload {
  border: dashed 2px #cbdaf2;
  cursor: pointer !important;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer !important;
  font-size: 11px;
  height: 92px;
  border-radius: 3px;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}

.fileContainer [type="file"] {
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  cursor: pointer;
}

.fileContainer img {
  height: 32px;
  width: 32px;
}
</style>
